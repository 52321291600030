/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

html,
body,
#root {
  height: 100%;
  /* font-size: 0.95em; */
}

.white-font-override {
  color: white !important;
}

input,
textarea,
.dark-form-control {
  background-color: #373a3c;
  color: white;
  border-color: gray;
}
.dark-form-control:focus {
  background-color: #373a3c;
  color: white;
  border-color: gray;
}
.dark-form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #373a3c inset;
  -webkit-text-fill-color: white !important;
}

/* @font-face {
  font-family: "Maison Neue";
  src: url(/public/fonts/maison-neue/MaisonNeueWEB-Light.woff),url(/public/fonts/maison-neue/MaisonNeueWEB-Light.woff2) format("woff2");
  font-style: normal;
  font-weight: 300
}

@font-face {
  font-family: "Maison Neue";
  src: url(/public/fonts/maison-neue/MaisonNeueWEB-LightItalic.woff),url(/public/fonts/maison-neue/MaisonNeueWEB-LightItalic.woff2) format("woff2");
  font-style: italic;
  font-weight: 300
} */

@font-face {
  font-family: 'Maison Neue';
  src: url(./fonts/MaisonNeueWEB-Book.woff) format('woff2');
  font-style: normal;
  font-weight: 400;
}

/* @font-face {
  font-family: "Maison Neue";
  src: url(/public/fonts/maison-neue/MaisonNeueWEB-BookItalic.woff),url(/public/fonts/maison-neue/MaisonNeueWEB-BookItalic.woff2) format("woff2");
  font-style: italic;
  font-weight: 400
}

@font-face {
  font-family: "Maison Neue";
  src: url(/public/fonts/maison-neue/MaisonNeueWEB-Medium.woff),url(/public/fonts/maison-neue/MaisonNeueWEB-Medium.woff2) format("woff2");
  font-style: normal;
  font-weight: 500
}

@font-face {
  font-family: "Maison Neue";
  src: url(/public/fonts/maison-neue/MaisonNeueWEB-MediumItalic.woff),url(/public/fonts/maison-neue/MaisonNeueWEB-MediumItalic.woff2) format("woff2");
  font-style: italic;
  font-weight: 500
} */

@font-face {
  font-family: 'Maison Neue';
  src: url(./fonts/MaisonNeueWEB-Demi.woff) format('woff2');
  font-style: normal;
  font-weight: 500;
}

/* @font-face {
  font-family: "Maison Neue";
  src: url(/public/fonts/maison-neue/MaisonNeueWEB-BoldItalic.woff),url(/public/fonts/maison-neue/MaisonNeueWEB-BoldItalic.woff2) format("woff2");
  font-style: italic;
  font-weight: 600
}

@font-face {
  font-family: "Maison Neue";
  src: url(/public/fonts/maison-neue/MaisonNeueWEB-Demi.woff),url(/public/fonts/maison-neue/MaisonNeueWEB-Demi.woff2) format("woff2");
  font-style: normal;
  font-weight: 700
}

@font-face {
  font-family: "Maison Neue";
  src: url(/public/fonts/maison-neue/MaisonNeueWEB-DemiItalic.woff),url(/public/fonts/maison-neue/MaisonNeueWEB-DemiItalic.woff2) format("woff2");
  font-style: italic;
  font-weight: 700
} */

/* @font-face {
  font-family: "Maison Neue";
  src: url("./fonts/MaisonNeueWEB-Bold.woff") format('woff');
  font-weight: bold;
} */
