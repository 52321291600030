.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: inherit;
}

.wrapper[data-user-actions-disabled=true] {
    position: relative;
    opacity: 0.8;
    pointer-events: none;
    user-select: none; /* pointer-events: none still allows selection */
    cursor: default !important;
}

.wrapper.clickable {
    cursor: pointer;
    user-select: none;
}
