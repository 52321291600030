.main {
  width: 100%;
  padding: 8px;
}

.headertxt {
  padding: 12px 6px;
  font-size: 1.3em;
  font-weight: 300;
}

.main td,
.main th {
  padding: 8px 8px;
  vertical-align: top;
  /* border: 1px solid rgb(230, 230, 230); */
}

.main .list td {
  padding: 3px 8px;
}

.main.firstItemBold tr:first-child {
  font-weight: bold;
}

.main td:first-child {
  font-weight: 300;
  opacity: 0.5;
  font-size: 0.9em;
  white-space: nowrap;
  width: 136px;
}

/* .main td {
  vertical-align: top;
} */

.link {
  text-decoration: underline;
  display: inline-block
}

.link:hover {
  text-decoration: underline;
  color: #007bff;
  cursor: pointer;
}

tbody tr {
  border-bottom: 0px solid lightgrey;
}
